import React from "react";

// @material-ui/core components
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";

// core components
import {
  secondaryColor,
  whiteColor,
} from "styles/jss/nextjs-material-kit-pro.js";

const useStyles = makeStyles({
  progress: {
    color: secondaryColor[0],
    width: "4rem !important",
    height: "4rem !important",
  },
  wrapperDiv: {
    margin: "100px auto",
    padding: "0px",
    maxWidth: "360px",
    textAlign: "center",
    position: "relative",
    zIndex: "9999",
    top: "0",
  },
  iconWrapper: {
    display: "block",
  },
  title: {
    fontSize: 30,
    textAlign: 'center',
    fontWeight: '500',
    color: whiteColor,
    letterSpacing: 5,
    textTransform: 'uppercase'
  },
});

export default function PageChange(props) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.wrapperDiv}>
        <div className={classes.iconWrapper}>
          <CircularProgress className={classes.progress} />
        </div>
      </div>
    </div>
  );
}
