import React from "react";
import ReactDOM from "react-dom/client";
import App from "next/app";
import Head from "next/head";
import Script from "next/script";
import Router from "next/router";

import PageChange from "components/PageChange/PageChange.js";

import "styles/scss/nextjs-material-kit-pro.scss?v=1.2.0";

import { createTheme, ThemeProvider } from "@mui/material";
import materialThemeOptions from "styles/jss/material-theme.js";

const theme = createTheme(materialThemeOptions);

// Handle long load presentation screen
let root;
Router.events.on("routeChangeStart", (url) => {
  root = ReactDOM.createRoot(document.getElementById("page-transition"));
  document.querySelector("body").classList.add("body-page-transition");
  root.render(<PageChange path={url} />);
});
Router.events.on("routeChangeComplete", () => {
  root.unmount();
  document.querySelector("body").classList.remove("body-page-transition");
});
Router.events.on("routeChangeError", () => {
  root.unmount();
  document.querySelector("body").classList.remove("body-page-transition");
});

export default class MyApp extends App {
  static async getInitialProps({ Component, router, ctx }) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps };
  }
  componentDidMount() {
    // Configure viewport height
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }
  render() {
    const { Component, pageProps } = this.props;

    return (
      <React.Fragment>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no"
          />
          <meta name="theme-color" content="#ff2b54"/>
          <title>Styler</title>
        </Head>
        <div id="root">
          <ThemeProvider theme={theme}>
            <Component {...pageProps} />
          </ThemeProvider>
        </div>
      </React.Fragment>
    );
  }
}
